<template>
	<div>
		<Header :title="isEdit ? '修改场次定价规则' : '添加场次定价规则'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
		<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
			:wrapperCol="{span: 14, xxl: 13 }" @finish="onSubmit">
			
			<a-form-item name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
				<a-select :disabled="isSee" placeholder="请选择" v-model:value="modelRef.organizationId" @change="getAllCinemaList">
					<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
				</a-select>
			</a-form-item>
			
			<a-form-item name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
				<a-select :disabled="isSee" placeholder="请选择" v-model:value="modelRef.cinemaId" @change="onCinameChange">
					<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
				</a-select>
			</a-form-item>

			<a-form-item label="规则名称" name="pricingName" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input :disabled="isSee" v-model:value="modelRef.pricingName" placeholder="请输入规则名称"></a-input>
			</a-form-item>

			<a-form-item label="优先级" name="pricingLevel" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input-number :disabled="isSee" v-model:value="modelRef.pricingLevel" :min="0" :precision="0" placeholder="请输入"></a-input-number> <span
					style="color: red;">数字越大,优先级越大;如果同级,最新创建的优先级大</span>
			</a-form-item>

			<a-form-item label="规则类型" name="versionType" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-radio-group :disabled="isSee" v-model:value="modelRef.versionType" button-style="solid">
					<a-radio-button :value="1">批量定价</a-radio-button>
					<a-radio-button :value="2">场次定价</a-radio-button>
				</a-radio-group>
			</a-form-item>

			<div v-if="modelRef.versionType === 1">
				<a-form-item label="影片规则" name="filmType" :rules="[{required: true, message: '必填项不允许为空'}]" extra="选择所属影院后开启其它选择项">
					<div class="ui-flex">
						<a-radio-group :disabled="isSee" v-model:value="modelRef.filmType" button-style="solid" @change="modelRef.filmList = [];movieList = [];">
							<a-radio-button :value="1">不限制</a-radio-button>
							<a-radio-button :value="2" :disabled="!modelRef.cinemaId">指定可用影片</a-radio-button>
							<a-radio-button :value="3" :disabled="!modelRef.cinemaId">指定不可用影片</a-radio-button>
						</a-radio-group>
						<span style="margin-left: 20px;" v-if="modelRef.filmType !== 1">
							<a-form-item-rest>
								<movie :id="modelRef.cinemaId" :disabled="isSee" v-model:value="modelRef.filmList" @change="(dataList) => { movieList = dataList; }"></movie>
							</a-form-item-rest>
						</span>
					</div>
					<div v-if="movieList.length" style="margin: 10px 0;">
						<a-table rowKey="id" :columns="movieColumns" :dataSource="movieList" size="small" :pagination="false">
							<template #bodyCell="{ column, record, index }">
								<template v-if="column.key === 'action'">
									<a-button :disabled="isSee" type="link" @click="onDeleteMovie(record, index)">删除</a-button>
								</template>
							</template>
						</a-table>
					</div>
				</a-form-item>

				<a-form-item label="影厅规则" name="hallType" :rules="[{required: true, message: '必填项不允许为空'}]" extra="选择所属影院后开启其它选择项">
					<div class="ui-flex">
						<a-radio-group :disabled="isSee" v-model:value="modelRef.hallType" button-style="solid" @change="modelRef.hallList = [];hallDataList = [];">
							<a-radio-button :value="1">不限制</a-radio-button>
							<a-radio-button :value="2" :disabled="!modelRef.cinemaId">指定可用影厅</a-radio-button>
							<a-radio-button :value="3" :disabled="!modelRef.cinemaId">指定不可用影厅</a-radio-button>
						</a-radio-group>
						<span style="margin-left: 20px;" v-if="modelRef.hallType !== 1">
							<a-form-item-rest>
								<cinema :disabled="isSee" :id="modelRef.cinemaId" v-model:value="modelRef.hallList" @change="(dataList) => {hallDataList = dataList}"></cinema>
							</a-form-item-rest>
						</span>
					</div>
					<div v-if="hallDataList.length" style="margin: 10px 0;">
						<a-table rowKey="id" :columns="hallColumns" :dataSource="hallDataList" size="small" :pagination="false">
							<template #bodyCell="{ column, record, index }">
								<template v-if="column.key === 'action'">
									<a-button :disabled="isSee" type="link" @click="onDeleteHall(record, index)">删除</a-button>
								</template>
							</template>
						</a-table>
					</div>
				</a-form-item>

				<a-form-item label="视觉规则" name="visualType" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-radio-group :disabled="isSee" v-model:value="modelRef.visualType" button-style="solid">
						<a-radio-button :value="1">不限制</a-radio-button>
						<a-radio-button :value="2">指定可用视觉</a-radio-button>
						<a-radio-button :value="3">指定不可用视觉</a-radio-button>
					</a-radio-group>
				</a-form-item>

				<a-form-item v-if="modelRef.visualType !== 1" label="选择视觉" name="visualList" :rules="[{required: true, message: '必选项不允许为空'}]">
					<a-form-item-rest>
						<a-checkbox :disabled="isSee" v-model:checked="checkAll" :indeterminate="indeterminate" @change="onCheckAllChange">全选</a-checkbox>
					</a-form-item-rest>
					<a-checkbox-group :disabled="isSee" v-model:value="modelRef.visualList">
						<a-checkbox :value="1">2D</a-checkbox>
						<a-checkbox :value="2">3D</a-checkbox>
						<a-checkbox :value="3">4D</a-checkbox>
						<a-checkbox :value="4">IMAX2D</a-checkbox>
						<a-checkbox :value="5">IMAX3D</a-checkbox>
					</a-checkbox-group>
				</a-form-item>

				<a-form-item label="放映时间">
					<div class="ui-time__header">
						<span class="ui-time__span">日期</span>
						<a-date-picker :disabled="isSee" v-model:value="modelRef.startDate" />
						<span class="ui-time__span">至</span>
						<a-date-picker :disabled="isSee" v-model:value="modelRef.endDate" />
						<a class="ui-time__span" @click="onAddSet">
							<Icon icon="PlusOutlined" style="margin-right: 2px;"></Icon>添加适用时间段
						</a>
					</div>
					<div class="ui-time__set" v-for="(item,index) in modelRef.timeList" :key="index">
						<div>
							<span class="ui-time__span">周期</span>
							<a-checkbox-group :disabled="isSee" v-model:value="item.week">
								<a-checkbox :value="7">周日</a-checkbox>
								<a-checkbox :value="1">周一</a-checkbox>
								<a-checkbox :value="2">周二</a-checkbox>
								<a-checkbox :value="3">周三</a-checkbox>
								<a-checkbox :value="4">周四</a-checkbox>
								<a-checkbox :value="5">周五</a-checkbox>
								<a-checkbox :value="6">周六</a-checkbox>
							</a-checkbox-group>
						</div>
						<div class="ui-margin__top20">
							<span class="ui-time__span">时段</span>
							<a-time-picker :disabled="isSee" format="HH:mm" v-model:value="item.startTime" />
							<span class="ui-time__span">至</span>
							<a-time-picker :disabled="isSee" format="HH:mm" v-model:value="item.endTime" />
							<a class="ui-time__span" @click="onDelSetting(index)">
								<Icon icon="DeleteOutlined"></Icon> 删除
							</a>
						</div>
					</div>
				</a-form-item>
			</div>
			<div v-else-if="modelRef.versionType === 2">
				<a-form-item label="场次" name="showList">
					<a-form-item-rest>
						<screen :disabled="isSee" v-model:value="modelRef.showList" :id="modelRef.cinemaId"></screen>
					</a-form-item-rest>
				</a-form-item>
			</div>

			<a-form-item label="定价方式">
				<div>
					<a-radio-group :disabled="isSee" v-model:value="modelRef.pricingSettings.firstMode" button-style="solid" @change="onFirstModeChange">
						<a-radio-button :value="1">挂牌价</a-radio-button>
						<a-radio-button :value="2">最低限价</a-radio-button>
						<a-radio-button :value="3">固定价</a-radio-button>
					</a-radio-group>
				</div>
				<div v-if="modelRef.pricingSettings.firstMode === 1">
					<div class="ui-margin__top20">
						<a-radio-group :disabled="isSee" v-model:value="modelRef.pricingSettings.secondMode" button-style="solid">
							<a-radio-button :value="1">折扣加</a-radio-button>
							<a-radio-button :value="2">折扣减</a-radio-button>
						</a-radio-group>
					</div>
					<div class="ui-margin__top20">
						<span class="ui-time__span">结算价</span> =
						<span class="ui-time__span">挂牌价 </span> x
						<a-input-number :disabled="isSee" class="ui-input" :min="0" v-model:value="modelRef.pricingSettings.discount" placeholder="请输入折扣百分比"></a-input-number> %
						{{ modelRef.pricingSettings.secondMode === 1 ? '+' : '-' }}
						<a-input-number :disabled="isSee" :min="0" v-model:value="modelRef.pricingSettings.price" class="ui-input" placeholder="请输入价格"></a-input-number>
					</div>
				</div>
				<div v-else-if="modelRef.pricingSettings.firstMode === 2">
					<div class="ui-margin__top20">
						<a-radio-group :disabled="isSee" v-model:value="modelRef.pricingSettings.secondMode" button-style="solid">
							<a-radio-button :value="1">限价加</a-radio-button>
							<a-radio-button :value="2">限价减</a-radio-button>
						</a-radio-group>
					</div>
					<div class="ui-margin__top20">
						<span class="ui-time__span">结算价</span> =
						<span class="ui-time__span">最低限价 </span>
						{{ modelRef.pricingSettings.secondMode === 1 ? '+' : '-' }}
						<a-input-number :min="0" :disabled="isSee" v-model:value="modelRef.pricingSettings.price" class="ui-input" placeholder="请输入价格"></a-input-number>
					</div>
				</div>
				<div v-else-if="modelRef.pricingSettings.firstMode === 3">
					<div class="ui-margin__top20">
						<span class="ui-time__span">结算价</span> =
						<a-input-number :min="0" :disabled="isSee" v-model:value="modelRef.pricingSettings.price" class="ui-input" placeholder="请输入价格"></a-input-number>
					</div>
				</div>
			</a-form-item>

			<a-form-item label="适用平台" name="source" :rules="[{ required: true, message: '必选项不允许为空' }]">
				<a-checkbox-group v-model:value="modelRef.source" :disabled="isSee">
					<a-checkbox :value="1">微信</a-checkbox>
					<a-checkbox :value="2">抖音</a-checkbox>
				</a-checkbox-group>
			</a-form-item>

			<a-form-item label="是否启用" name="isDisabled">
				<a-switch :disabled="isSee" v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />

				<a-form-item-rest>
					<div class="ui-margin__top40">
						<label>
							<a-checkbox v-model:checked="isRead" @change="onReadChange"></a-checkbox>
							<span class="ui-time__span">我已阅读注意事项</span>
						</label>
					</div>
				</a-form-item-rest>
			</a-form-item>

			<a-row>
				<a-col :span="24" style="margin-top: 20px;text-align: center;">
					<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
					<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
				</a-col>
			</a-row>
		</a-form>

		<a-modal v-model:visible="readVisible" :closable="false" :maskClosable="false" title="注意事项">
			<template #footer>
				<a-button type="primary" @click="readVisible = false" :disabled="readTime !== 0">{{ readTime === 0 ? '我已阅读' : '已阅读 ' + readTime + 's' }}</a-button>
			</template>
			<p style="color: rgba(0,0,0,.65);">
				* 设置基础定价（如添加、删除、修改数据）后，都需要点击同步定价规则按钮才能生效；
			</p>
		</a-modal>
		</a-spin>
	</div>
</template>

<script>
	// import dayjs from 'dayjs';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import screen from '@/components/screen/index.vue';
	import movie from '@/components/movie/index.vue';
	import cinema from '@/components/cinema/index.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getPricePolicyDetail, savePricePolicy, updatePricePolicy } from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
			Header,
			screen,
			movie,
			cinema
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		created() {
			this.getOrganizationList();
			if (this.isEdit && this.id) {
				this.getData();
			}
		},
		data() {
			return {
				loading: false,
				isRead: false,
				organizationList: [],
				cinemaAllList: [],
				modelRef: {
					pricingLevel: 0,
					isDisabled: false,
					versionType: 1,
					filmType: 1,
					filmList: [],
					hallType: 1,
					hallList: [],
					visualType: 1,
					visualList: [],
					timeList: [],
					pricingSettings: {
						firstMode: 1,
						secondMode: 1
					},
					showList: [],
				},
				readVisible: false,
				readTime: 3,
				checkAll: false,
				indeterminate: false,
				
				movieList: [],
				movieColumns: [{
					title: '影片编码',
					dataIndex: 'code'
				}, {
					title: '影片名称',
					dataIndex: 'shortName'
				},{
					title: '语言',
					dataIndex: 'language',
					width: 80,
					align: 'center'
				},{
					title: '操作',
					key: 'action'
				}],
				hallDataList: [],
				hallColumns: [{
					title: '影厅编码',
					dataIndex: 'code'
				}, {
					title: '影厅名称',
					dataIndex: 'name'
				}, {
					title: '操作',
					key: 'action'
				}]
			}
		},
		watch: {
			'modelRef.visualList'(newVal) {
				this.indeterminate = !!newVal.length && newVal.length < 5;
				this.checkAll = newVal.length === 5;
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getPricePolicyDetail({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						ret.data.pricingSettings = ret.data.pricingSettings ? JSON.parse(ret.data.pricingSettings) : {
							firstMode: 1,
							secondMode: 1,
						};
						if (ret.data.startDate) {
							ret.data.startDate = this.moment(ret.data.startDate * 1000);
						} else {
							ret.data.startDate = undefined;
						}
						if (ret.data.endDate) {
							ret.data.endDate = this.moment(ret.data.endDate * 1000);
						} else {
							ret.data.endDate = undefined;
						}
						ret.data.filmList = ret.data.filmList ? ret.data.filmList.split(',') : [];
						ret.data.hallList = ret.data.hallList ? ret.data.hallList.split(',') : [];
						ret.data.showList = ret.data.showList ? ret.data.showList.split(',').map(item=> Number(item)) : [];
						ret.data.isDisabled = ret.data.isDisabled ? false : true;
						ret.data.visualList = ret.data.dimensionalList ? ret.data.dimensionalList.split(',').map(item => Number(item)) : [];
						ret.data.timeList = JSON.parse(ret.data.timeList);
						ret.data.timeList.forEach(item => {
							let startTime = this.moment.duration(Number(item.startTime), 'second').hours() + ':' + this.moment.duration(Number(item.startTime), 'second').minutes();
							let endTime = this.moment.duration(Number(item.endTime), 'second').hours() + ':' + this.moment.duration(Number(item.endTime), 'second').minutes();
							item.week = item.week.split(',').map(item => Number(item));
							item.startTime =  this.moment(startTime, 'HH:mm');
							item.endTime = this.moment(endTime, 'HH:mm');
						});
						this.getAllCinemaList(ret.data.organizationId);
						// if (ret.data.cinemaFilmList) {}
						this.movieList = ret.data.cinemaFilmList ? ret.data.cinemaFilmList.map(item => {
							item.id = item.filmId.toString();
							item.code = item.filmCode;
							item.shortName = item.film;
							return item;
						}) : [];
						this.hallDataList = ret.data.cinemaHallList ? ret.data.cinemaHallList.map(item => {
							item.id = item.hallId.toString();
							item.name = item.hall;
							item.code = item.hallCode;
							return item;
						}) : [];
						delete ret.data.cinemaFilmList;
						delete ret.data.cinemaHallList;
						
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.modelRef.cinemaId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onOrganizationChange(id) {
				this.onCinameChange();
				this.getAllCinemaList(id);
			},
			async onSubmit() {
				if (!this.isRead) {
					return this.$message.warn('请阅读注意事项');
				}
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				if (postData.pricingSettings.firstMode === 1
					&& (postData.pricingSettings.discount == null || isNaN(Number(postData.pricingSettings.discount)))
					|| (postData.pricingSettings.price == null || isNaN(Number(postData.pricingSettings.price)))
				) {
					this.$message.error('定价方式的结算价不能为空！');
					return;
				}
				if (postData.pricingSettings.firstMode === 2 && (postData.pricingSettings.price == null || isNaN(Number(postData.pricingSettings.price)))) {
					this.$message.error('定价方式的结算价不能为空！');
					return;
				}
				if (postData.pricingSettings.firstMode === 3 && (postData.pricingSettings.price == null || isNaN(Number(postData.pricingSettings.price)))) {
					this.$message.error('定价方式的结算价不能为空！');
					return;
				}
				if (postData.filmType !== 1 && postData.filmList.length === 0) {
					this.$message.error('影片列表不能为空，请选择影片！')
					return;
				}
				if (postData.hallType !== 1 && postData.hallList.length === 0) {
					this.$message.error('影厅列表不能为空，请选择影厅！');
					return;
				}
				postData.filmList = postData.filmList.join(',');
				postData.hallList = postData.hallList.join(',');
				postData.dimensionalList = postData.visualList.join(',');
				postData.showList = postData.showList.join(',');
				postData.isDisabled = postData.isDisabled ? 0 : 1;
				if (postData.startDate) {
					postData.startDate = parseInt(this.moment(postData.startDate).startOf('day').valueOf() / 1000);
				} else {
					postData.startDate = 0;
				}
				if (postData.endDate) {
					postData.endDate = parseInt(this.moment(postData.endDate).endOf('day').valueOf() / 1000);
				} else {
					postData.endDate = 0;
				}
				postData.timeList = postData.timeList.map((item) => {
					return {
						week: item.week.join(','),
						startTime: this.moment(item.startTime).get('hour') * 60 *60 + this.moment(item.startTime).get('minute') * 60,
						endTime: this.moment(item.endTime).get('hour') * 60 *60 + this.moment(item.endTime).get('minute') * 60
					}
				});
				
				try {
					let ret;
					this.loading = true;
					if (this.isEdit && this.id) {
						postData.id = this.id;
						ret = await updatePricePolicy(postData);
					} else {
						ret = await savePricePolicy(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.onBack(true);
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onCinameChange() {
				this.modelRef.filmType = 1;
				this.modelRef.filmList = [];
				this.movieList = [];
				this.modelRef.hallType = 1;
				this.modelRef.hallList = [];
				this.hallDataList = [];
				this.modelRef.showList = [];
			},
			onFirstModeChange() {
				this.modelRef.pricingSettings.discount = '';
				this.modelRef.pricingSettings.price = '';
			},
			onAddSet() {
				if (this.isSee) return;
				this.modelRef.timeList.push({
					week: [7, 1, 2, 3, 4, 5, 6],
					startTime: this.moment('00:00', 'HH:mm'),
					endTime: this.moment('23:59', 'HH:mm')
				});
			},
			onDelSetting(index) {
				if (this.isSee) return;
				this.modelRef.timeList.splice(index, 1);
			},
			onReadChange(e) {
				if (e.target.checked) {
					this.readVisible = true;
					this.readTime = 3;
					this.timeCountDown();
				}
			},
			timeCountDown() {
				if (this.readTime > 0) {
					setTimeout(()=> {
						this.readTime -= 1;
						this.timeCountDown();
					}, 1000);
				}
			},
			onCheckAllChange(e) {
				if (e.target.checked) {
					this.modelRef.visualList = [1,2,3,4,5];
				} else {
					this.modelRef.visualList = [];
				}
			},
			onDeleteMovie(item, index) {
				let i = this.modelRef.filmList.indexOf(item.id);
				this.modelRef.filmList.splice(i, 1);
				this.movieList.splice(index, 1);
			},
			onDeleteHall(item, index) {
				let i = this.modelRef.hallList.indexOf(item.id);
				this.modelRef.hallList.splice(i, 1);
				this.hallDataList.splice(index, 1);
			}
		}
	}
</script>

<style scoped>
	.ui-flex {
		display: flex;
	}

	.ui-input {
		width: 180px;
		display: inline-block;
	}

	.ui-margin__top20 {
		margin-top: 20px;
	}

	.ui-margin__top40 {
		margin-top: 40px;
	}

	.ui-time__header {
		padding: 10px;
		color: rgba(0, 0, 0, .65);
		background-color: #f5f5f5;
	}

	.ui-time__span {
		padding: 0 10px;
	}

	.ui-time__set {
		padding: 20px 10px;
		border-bottom: solid 1px #eee;
		border-left: solid 1px #eee;
		border-right: solid 1px #eee;
	}
</style>