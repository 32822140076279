<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="refundName" label="规则名称">
						<a-input v-model:value="formState.refundName" placeholder="请输入规则名称"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="规则类型" name="versionType">
						<a-select v-model:value="formState.versionType" style="width: 190px;" placeholder="请选择规则类型">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">批量定价</a-select-option>
							<a-select-option :value="2">场次定价</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="是否有效" name="isValid">
						<a-select v-model:value="formState.isValid" style="width: 190px;" placeholder="请选择是否有效">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="1">有效</a-select-option>
							<a-select-option :value="0">无效</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="是否启用" name="isDisabled">
						<a-select v-model:value="formState.isDisabled" style="width: 190px;" placeholder="请选择是否启用">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
							<a-select-option :value="0">启用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['ciname_policy_pricing_add']" type="primary" @click="onAdd">添加</a-button>
						<a-button v-permission="['ciname_policy_pricing_sync']" type="primary" style="margin-left: 20px;" @click="onSync">同步定价规则</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1500 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'type'">
							{{ record.versionType === 1 ? '批量定价' : '场次定价' }}
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'time'">
							<div v-if="!record.startTime && !record.endTime">
								永久有效
							</div>
							<div v-else>
								{{ transDataTime(record.startTime, 1) }} - {{ transDataTime(record.endTime, 1) }}
							</div>
						</template>
						<template v-if="column.key === 'isTime'">
							<div style="color:green;" v-if="!record.startTime && !record.endTime">有效</div>
							<div v-else>
								<div style="color:green;" v-if="record.startTime >= currentTime && currentTime < record.endTime">
									有效
								</div>
								<div style="color:red;" v-else>无效</div>
							</div>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onSee(record)">
											<a-menu-item>
												查看
											</a-menu-item>
										</div>
										<div v-permission="['ciname_policy_pricing_edit']" @click="onEdit(record)">
											<a-menu-item>
												修改
											</a-menu-item>
										</div>
										<div v-permission="['ciname_policy_pricing_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
		</a-spin>
	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getPricePolicyList, deletePricePolicy, syncPricing } from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					isValid: -1,
					isDisabled: -1,
					versionType: 0,
					// organizationId: 0,
					cinemaId: 0
				},
				searchData: {},
				showModal: false,
				currentTime:0,
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinema'
				}, {
					title: '规则名称',
					dataIndex: 'pricingName'
				}, {
					title: '规则类型',
					key: 'type',
					width: 120
				}, {
					title: '优先级',
					dataIndex: 'pricingLevel',
					width: 100
				}, {
					title: '是否启用',
					key: 'isDisabled',
					width: 100
				}, {
					title: '有效期',
					key: 'time'
				}, {
					title: '是否有效',
					key: 'isTime',
					width: 100
				}, {
					title: '创建时间',
					key: 'createTime'
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				isSee: false,
				id: 0
			}
		},
		created() {
			this.currentTime = this.moment().startOf('day').valueOf() / 1000;
			this.getOrganizationList();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.versionType = this.searchData.versionType ? this.searchData.versionType : undefined;
				this.searchData.isValid = this.searchData.isValid >= 0 ? this.searchData.isValid : undefined;
				this.searchData.isDisabled = this.searchData.isDisabled >= 0 ? this.searchData.isDisabled : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getPricePolicyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onAdd() {
				this.id = 0;
				this.isEdit = false;
				this.isSee = false;
				this.showModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.isSee = false;
				this.showModal = true;
			},
			onSee(item) {
				this.id = item.id;
				this.isEdit = true;
				this.isSee = true;
				this.showModal = true;
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deletePricePolicy({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onSync() {
				if (!this.formState.cinemaId) {
					return this.$message.warn('请在上方搜索项中选择需要同步定价规则的所属影院！');
				} else {
					this.$confirm({
						title: '提示',
						content: '确定同步定价规则至影院吗？',
						onOk: async () => {
							this.loading = true;
							try {
								let ret = await syncPricing({
									cinemaId: this.formState.cinemaId
								});
								this.loading = false;
								if (ret.code === 200) {
									this.$message.success('同步完成！');
								}
							} catch(e) {
								this.loading = false;
							}
						}
					})
				}
			}
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
